import React from 'react';
import BookingSubHead from './BookingSubHead';
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { PassengerType } from '../../utils/constant';
import { getPerPaxTypeTotalTaxAmount } from '../../utils/utility/taxAdjustment';
import { useTranslation } from 'react-i18next';

function FareBreakDown(props) {
    const { t } = useTranslation();

    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    // const bookingDetail = useSelector((state) => state.flightBooking.bookingItenaries)
    let repriceQuote = useSelector((state) => state.savedQuotes.repriceQuote);
    const flightDetail = useSelector((state) => state.flightBooking);
    const bookingItenaries = flightDetail && flightDetail.bookingItenaries
    const paymentRules = useSelector((state) => state.flightBooking.paymentRules);
    let adultMarkup = useSelector((state) => state.searchForm.adultMarkupValue);
    let childMarup = useSelector((state) => state.searchForm.childMarkupValue);
    let infantMarkup = useSelector((state) => state.searchForm.infantMarkupValue);
    const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
    const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
    const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
    const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
    const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
    const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
    const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
    const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
    const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
    const fopSelection = useSelector((state) => state.flightBooking.fopSelection);

    let { open, from, data ,flowFrom} = props
    const bookingDetail = useSelector((state) => state.flightBooking.bookingItenaries)
    if(!data){
        data = bookingDetail
    }
    let commissionDetails = data && data.applicableContract && data.applicableContract.commissionDetails && data.applicableContract.commissionDetails.commissionUsedForCalculation;
    let adultCommisionPercentage = commissionDetails && commissionDetails?.adult?.amount;
    let childCommisionPercentage = commissionDetails && commissionDetails?.child?.amount;
    let infantCommisionPercentage = commissionDetails && commissionDetails?.infant?.amount;

    let costCommission = data && data.applicableContract && data.applicableContract.commissionDetails;
    let costAdultCommission = costCommission && costCommission.amountPerAdult && costCommission.amountPerAdult.amount;
    let costChildCommission = costCommission && costCommission.amountPerChild && costCommission.amountPerChild.amount;
    let costInfantCommission = costCommission && costCommission.amountPerInfantInLap && costCommission.amountPerInfantInLap.amount;

    let netCommission = data && data.applicableContract && data.applicableContract.passBackCommission;
    let netAdultCommission = netCommission && netCommission.amountPerAdult && netCommission.amountPerAdult.amount;
    let netChildCommission = netCommission && netCommission.amountPerChild && netCommission.amountPerChild.amount;
    let netInfantCommission = netCommission && netCommission.amountPerInfantInLap && netCommission.amountPerInfantInLap.amount;
    let isDuffleFlow = data && data.providerName && data.providerName == "NDC" ? true:false;

    let prixKeys = data && data.applicableContract && data.applicableContract.prixKeys ? data.applicableContract.prixKeys : []
    const faresPerTravellers = data && data.paxFares
    let type = data && data.allMatchingContracts
    let allMatchingContracts = data && data.allMatchingContracts
    let fareTypes = data && data.fareType;
    
    let passengerData = bookingData && bookingData.passengers
    const bookingCode = flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code
    
    let fareDetails = repriceQuote && repriceQuote.fareDetails
    let quoteDetail = fareDetails && fareDetails.itineraryFare
    const merchantDetail = bookingData && bookingData.merchantFeeAppliedOnTransaction;

    let adultCount
    let childsCount
    let infantsCount

    

    if (pnrFlag) {
        adultCount = pnrAdultsCount
        childsCount = pnrChildCount
        infantsCount = pnrInfantCount
    } else {
        adultCount = searchAdultCOunt
        childsCount = searchChildCount
        infantsCount = searchInfantCount
    }

    
    // MARKUP LINE ITEM HANDLING ON THE BASIS OF FOP SELECTION
    let fopSlectionCheck = fopSelection && fopSelection.filter(val => val.paxType === "All")
    let markupCheckFlagForAdult = false;
    let markupCheckFlagForChild = false;
    let markupCheckFlagForInfant = false;
    
  
    if(fopSlectionCheck && fopSlectionCheck.length > 0){
      let seletedOptionOfFop = fopSlectionCheck && fopSlectionCheck[0] && fopSlectionCheck[0].type;
      if(seletedOptionOfFop === "Cheque"){
        markupCheckFlagForAdult = true;
        markupCheckFlagForChild = true;
        markupCheckFlagForInfant = true;
      }
  
  
    }

    let adultPaxTypeArr;
    let childPaxTypeArr;
    let infantPaxTypeArr;
    const handleCardOption = () => {
    let chequeOptionArr = fopSelection && fopSelection.filter(val => val.type === "Cheque");
    if(chequeOptionArr && chequeOptionArr.length > 0){
        infantPaxTypeArr = chequeOptionArr && chequeOptionArr.filter(obj => obj.paxType === "InfantInLap");
        adultPaxTypeArr = chequeOptionArr && chequeOptionArr.filter(obj => obj.paxType === "Adult");
        childPaxTypeArr = chequeOptionArr && chequeOptionArr.filter(obj => obj.paxType === "Child");
    }

    }



    handleCardOption()
// MARKUP LINE ITEM HANDLING ON THE BASIS OF FOP SELECTION END
    let priceValue;
    let fareType;
    let netAdultMarkup = 0
    let netChildMarkup = 0
    let netInfantMarkup = 0
    let adultCommision = 0
    let childCommision = 0
    let infantCommision = 0
    let otherRateComponents;
    let totalAmount = 0;
    let travlerCount = 0;
    let paxTypeCodeArray = []
    let isPrivate = false;



    faresPerTravellers && faresPerTravellers.map(data => {
        let paxTypeCode = data && data.passengerDetails && data.passengerDetails.code
        if (!paxTypeCodeArray.includes(paxTypeCode)) {
            paxTypeCodeArray.push(paxTypeCode)
        }
    })



    if (props.from === "quote") {
        priceValue = props.priceValue
        fareType = quoteDetail && quoteDetail.type
        prixKeys = priceValue && priceValue.applicableContract && priceValue.applicableContract.prixKeys ? priceValue.applicableContract.prixKeys : []
    }
    else if (props.from === "quoteList") {
        fareType = type
        priceValue = data
    }

    else {
        priceValue = bookingDetail
        fareType = bookingItenaries && bookingItenaries.type
    }

    if (props.from === "quoteList") {
        otherRateComponents = data && data.otherRateComponents
    } else {
        otherRateComponents = priceValue && priceValue.otherRateComponents
    }

    let markup = priceValue && priceValue.applicableContract && priceValue.applicableContract.markup
    let passbackCommision = priceValue && priceValue.applicableContract && priceValue.applicableContract.passBackCommission
    let paxTypeAmount = priceValue && priceValue.paxFares;

    let agencyMarkups
    if(from ==="quoteList"){
        agencyMarkups = props.agencyMarkups
    } else{
         agencyMarkups = bookingData && bookingData.agencyMarkups
    }
   
    let PassengerWiseTransactionsDetails = paymentRules && paymentRules.PassengerWiseTransactionsDetails

    let adultsCount = 0
    let childCount = 0
    let infantCount = 0
    let adultMarkupValue = adultMarkup
    let childMarkupValue = childMarup
    let infantMarkupValue = infantMarkup
    let adultMerchantfee = 0
    let childMerchantfee = 0
    let infantMerchantfee = 0
    let amountInfo = [];
    let commitionLabel;
    let taxAdjustmentAmtForAdult = 0;
    let taxAdjustmentAmtForChild = 0;
    let taxAdjustmentAmtForInfant= 0;

    if (from === "search") {
        adultsCount = adultCount
        childCount = childsCount
        infantCount = infantsCount
        fareType = data.type ? data.type : props.fareType
        isPrivate = data && data.isPrivate ? data.isPrivate : false
        let paxTypeData = faresPerTravellers
        commitionLabel = fareTypes
        passbackCommision = data && data.applicableContract && data.applicableContract.passBackCommission
        markup = data && data.applicableContract && data.applicableContract.markup
        netAdultMarkup = markup && markup.amountPerAdult && markup.amountPerAdult.amount ? markup.amountPerAdult.amount : 0
        netChildMarkup = markup && markup.amountPerChild && markup.amountPerChild.amount ? markup.amountPerChild.amount : 0
        netInfantMarkup = markup && markup.amountPerInfantWithSeat && markup.amountPerInfantWithSeat.amount ? markup.amountPerInfantWithSeat.amount : 0
        adultCommision = passbackCommision && passbackCommision.amountPerAdult && passbackCommision.amountPerAdult.amount ? passbackCommision.amountPerAdult.amount : 0
        childCommision = passbackCommision && passbackCommision.amountPerChild && passbackCommision.amountPerChild.amount ? passbackCommision.amountPerChild.amount : 0
        infantCommision = passbackCommision && passbackCommision.amountPerInfantWithSeat && passbackCommision.amountPerInfantWithSeat.amount ? passbackCommision.amountPerInfantWithSeat.amount : 0

        let amount = 0
        paxTypeData && paxTypeData.map((paxType, indexCount) => {
            let passengerDetails = paxType.passengerDetails;
            let type = passengerDetails && passengerDetails.type;
            let quantity;
            let totalTaxes = paxType.totalTaxes
            if (type === PassengerType.ADULT) {
                quantity = adultCount
                totalTaxes = Number(totalTaxes.amount);      
            }
            if (type === PassengerType.CHILD) {
                quantity = childCount
                totalTaxes = Number(totalTaxes.amount); 
            }
            if (type === PassengerType.INFANTINLAP) {
                quantity = infantCount
                totalTaxes = Number(totalTaxes.amount);
            }
            let totalAmount = paxType.totalAmount
            let baseAmount = paxType.baseAmount
            let sellingBaseAmount = paxType.fareBreakDown.sellingBaseAmount 
            let isPresent = amountInfo.filter(obj => obj.type === type)
            let supplierTotalAmount = paxType.supplierTotalAmount
            let ticketFee = paxType.fareBreakDown.ticketingFee
            let bookFee = paxType.fareBreakDown.bookingFee

            if (isPresent.length === 0) {
                let passenger = {
                    type: type,
                    quantity: quantity,
                    totalAmount: totalAmount.amount,
                    baseAmount: baseAmount.amount,
                    totalTaxes: totalTaxes,
                    sellingBaseAmount: sellingBaseAmount && sellingBaseAmount.amount,
                    supplierTotalAmount: supplierTotalAmount && supplierTotalAmount.amount,
                    ticketingFee: ticketFee && ticketFee.amount,
                    bookingFee: bookFee && bookFee.amount
                }
                amountInfo.push(passenger)
            }

        })

    }

    else {
        netAdultMarkup = markup && markup.amountPerAdult && markup.amountPerAdult.amount ? markup.amountPerAdult.amount : 0
        netChildMarkup = markup && markup.amountPerChild && markup.amountPerChild.amount ? markup.amountPerChild.amount : 0
        netInfantMarkup = markup && markup.amountPerInfant && markup.amountPerInfant.amount ? markup.amountPerInfant.amount : 0
        adultCommision = passbackCommision && passbackCommision.amountPerAdult && passbackCommision.amountPerAdult.amount ? passbackCommision.amountPerAdult.amount : 0
        childCommision = passbackCommision && passbackCommision.amountPerChild && passbackCommision.amountPerChild.amount ? passbackCommision.amountPerChild.amount : 0
        infantCommision = passbackCommision && passbackCommision.amountPerInfant && passbackCommision.amountPerInfant.amount ? passbackCommision.amountPerInfant.amount : 0
    }


    if (PassengerWiseTransactionsDetails && PassengerWiseTransactionsDetails.length > 0) {
    } else {
        if (agencyMarkups && agencyMarkups.length > 0) {

            let adultsData = agencyMarkups.filter(obj => obj.paxType === PassengerType.ADULT)
            let infantData = agencyMarkups.filter(obj => obj.paxType === PassengerType.INFANTINLAP)
            let childData = agencyMarkups.filter(obj => obj.paxType === PassengerType.CHILD)

            if (adultsData && adultsData[0]) {
                adultsCount = adultsData[0].quantity
                adultMarkupValue = adultsData[0].perPaxAmount && adultsData[0].perPaxAmount.amount
            }

            if (childData && childData[0]) {
                childCount = childData[0].quantity
                childMarkupValue = childData[0].perPaxAmount && childData[0].perPaxAmount.amount
            }
            if (infantData && infantData[0]) {
                infantCount = infantData[0].quantity
                infantMarkupValue = infantData[0].perPaxAmount && infantData[0].perPaxAmount.amount
            }

        }
        if (from === "confirmation") {
            if (merchantDetail) {
                let arr = merchantDetail.PassengerwiseMerchantFee
                let newArr = []
                arr.map(obj => {
                    let matchedArr = passengerData.filter(passnger => passnger.id === obj.id)
                    let paxType = matchedArr && matchedArr[0] && matchedArr[0].paxType
                    if (paxType === PassengerType.ADULT) {
                        adultMerchantfee = obj.amount && obj.amount.amount
                    }

                    if (paxType === PassengerType.CHILD) {
                        childMerchantfee = obj.amount && obj.amount.amount
                    }

                    if (paxType === PassengerType.INFANTINLAP) {
                        infantMerchantfee = obj.amount && obj.amount.amount
                    }


                })
            }

        } else {
            if (PassengerWiseTransactionsDetails && PassengerWiseTransactionsDetails.length > 0) {

                PassengerWiseTransactionsDetails.map(obj => {

                    if (adultMarkupValue > 0) {
                        if (obj.paxType === PassengerType.ADULT) {
                            let transaction = obj.transactionsDetails
                            let data = transaction.filter(newObj => newObj.merchant === "Intair")
                            adultMerchantfee = data.length > 0 ? data[0] && data[0].merchantFee && data[0] && data[0].merchantFee.totalAmount.amount : 0
                        }

                    }
                    if (childMarkupValue > 0) {
                        if (obj.paxType === PassengerType.CHILD) {
                            let transaction = obj.transactionsDetails
                            let data = transaction.filter(newObj => newObj.merchant === "Intair")
                            childMerchantfee = data.length > 0 ? data[0] && data[0].merchantFee && data[0] && data[0].merchantFee.totalAmount.amount : 0
                        }
                    }
                    if (infantMarkupValue > 0) {
                        if (obj.paxType === PassengerType.INFANTINLAP) {
                            let transaction = obj.transactionsDetails
                            let data = transaction.filter(newObj => newObj.merchant === "Intair")
                            infantMerchantfee = data.length > 0 ? data[0] && data[0].merchantFee && data[0] && data[0].merchantFee.totalAmount.amount : 0
                        }
                    }

                })
            }
        }


    }



    if (from !== "search") {

        let paxTypeAmount = priceValue && priceValue.paxFares
        commitionLabel = priceValue && priceValue.fareType

        adultsCount = adultCount
        childCount = childsCount
        infantCount = infantsCount
        prixKeys = priceValue && priceValue.applicableContract && priceValue.applicableContract.prixKeys ? priceValue.applicableContract.prixKeys : []
        isPrivate = priceValue && priceValue.isPrivate ? priceValue.isPrivate : false
        paxTypeAmount && paxTypeAmount.map((paxType, index) => {
            let passengerDetails = paxType.passengerDetails;
            let type = passengerDetails && passengerDetails.type;
            let quantity = passengerDetails && passengerDetails.quantity
            let totalAmount = paxType.totalAmount
            let baseAmount = paxType.baseAmount
            let sellingBaseAmount = paxType.fareBreakDown.sellingBaseAmount
            let totalTaxes = paxType.totalTaxes
            let taxes = paxType.taxes
            let cnt = quantity;
            let paxTypeCode = paxType.passengerDetails.code
            let supplierTotalAmount = paxType.supplierTotalAmount
            let ticketFee = paxType.fareBreakDown.ticketingFee
            let bookFee = paxType.fareBreakDown.bookingFee

            totalTaxes = Number(totalTaxes.amount);
            // totalTaxes = Number(totalTaxes.amount) + Number(taxAdjustmentAmtForAdult); 
            if (!paxTypeCodeArray.includes(paxTypeCode)) {
                paxTypeCodeArray.push(paxTypeCode)
            }


            let ispreset = amountInfo.filter(obj => obj.type === type)

            if (ispreset.length === 0) {
                let passenger = {
                    type: type,
                    quantity: quantity,
                    totalAmount: totalAmount.amount,
                    baseAmount: baseAmount.amount,
                    totalTaxes: totalTaxes,
                    taxes: taxes,
                    sellingBaseAmount: sellingBaseAmount && sellingBaseAmount.amount,
                    supplierTotalAmount: supplierTotalAmount && supplierTotalAmount.amount,
                    ticketingFee: ticketFee && ticketFee.amount,
                    bookingFee: bookFee && bookFee.amount
                }
                amountInfo.push(passenger)
            }
        })
        costCommission = priceValue && priceValue.applicableContract && priceValue.applicableContract.commissionDetails;
        costAdultCommission = costCommission && costCommission.amountPerAdult && costCommission.amountPerAdult.amount;
        costChildCommission = costCommission && costCommission.amountPerChild && costCommission.amountPerChild.amount;
        costInfantCommission = costCommission && costCommission.amountPerInfantInLap && costCommission.amountPerInfantInLap.amount;

        netCommission = priceValue && priceValue.applicableContract && priceValue.applicableContract.passBackCommission;
        netAdultCommission = netCommission && netCommission.amountPerAdult && netCommission.amountPerAdult.amount;
        netChildCommission = netCommission && netCommission.amountPerChild && netCommission.amountPerChild.amount;
        netInfantCommission = netCommission && netCommission.amountPerInfantInLap && netCommission.amountPerInfantInLap.amount;
        isDuffleFlow = priceValue && priceValue.providerName && priceValue.providerName == "NDC" ? true :false; 

    }


    if (from === "quoteList") {
        adultsCount = adultCount
        childCount = childsCount
        infantCount = infantsCount
        fareType = data && data.type
        commitionLabel = fareTypes
        isPrivate = props.data && props.data.isPrivate ? props.data.isPrivate : false
        props.data && props.data.paxFares.map((paxType, index) => {

            let passengerDetails = paxType.passengerDetails;
            let type = passengerDetails && passengerDetails.type;
            let quantity = passengerDetails && passengerDetails.quantity
            let totalAmount = paxType.totalAmount
            let baseAmount = paxType.baseAmount
            let totalTaxes = paxType.totalTaxes
            let taxes = paxType.taxes
            let cnt = quantity;
            let paxTypeCode = paxType.passengerDetails.code
            let sellingBaseAmount = paxType.fareBreakDown.sellingBaseAmount          
            totalTaxes = Number(totalTaxes.amount); 
            let supplierTotalAmount = paxType.supplierTotalAmount
            let ticketFee = paxType.fareBreakDown.ticketingFee
            let bookFee = paxType.fareBreakDown.bookingFee


            if (!paxTypeCodeArray.includes(paxTypeCode)) {
                paxTypeCodeArray.push(paxTypeCode)
            }

            let ispreset = amountInfo.filter(obj => obj.type === type)

            if (ispreset.length === 0) {
                let passenger = {
                    type: type,
                    quantity: quantity,
                    totalAmount: totalAmount.amount,
                    baseAmount: baseAmount.amount,
                    totalTaxes: totalTaxes,
                    taxes: taxes,
                    sellingBaseAmount: sellingBaseAmount && sellingBaseAmount.amount,
                    supplierTotalAmount: supplierTotalAmount && supplierTotalAmount.amount,
                    ticketingFee: ticketFee && ticketFee.amount,
                    bookingFee: bookFee && bookFee.amount
                }
                amountInfo.push(passenger)

            }

        })

    }

    const handleClose = () => {
        props.handleClose()
    }

    if(flowFrom === "calculator"){
        adultMarkupValue = props.adultMarkupValue !== undefined ? props.adultMarkupValue : 0;
        childMarkupValue = props.childMarkupValue !== undefined ? props.childMarkupValue : 0;
        infantMarkupValue = props.infantMarkupValue !== undefined ? props.infantMarkupValue : 0;
    }

    
    // MARKUP LINE ITEM HANDLING ON THE BASIS OF FOP SELECTION
    let adultPaxTypeOptionCnt = adultPaxTypeArr && adultPaxTypeArr.length ;
    let childPaxTypeOptionCnt = childPaxTypeArr && childPaxTypeArr.length ;
    let infantPaxTypeOptionCnt = infantPaxTypeArr && infantPaxTypeArr.length ;   
    
  
   
      if(adultPaxTypeOptionCnt === adultsCount){   
          markupCheckFlagForAdult = true;
      }
      if(childPaxTypeOptionCnt === childCount){   
        markupCheckFlagForChild = true;
      }
      if(infantPaxTypeOptionCnt === infantCount){   
        markupCheckFlagForInfant = true;
      }
      

      if(markupCheckFlagForAdult){
        adultMarkupValue = 0;
      }
      if(markupCheckFlagForChild){
        childMarkupValue = 0;       
      }
      if(markupCheckFlagForInfant){     
        infantMarkupValue = 0;
      }

   // MARKUP LINE ITEM HANDLING ON THE BASIS OF FOP SELECTION END
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="fareType-dialog"
        >
            <div className="bd-pi-contact-modal bd-alertBox fareType-table">




                <div className="head">

                    {isInternalUserAutheticated ?
                        <div className='fd-subHead'>

                            {/* <span>{isPrivate && "Private |"}{fareType}</span> */}
                            <span>
                                {paxTypeCodeArray && paxTypeCodeArray.map((paxType, index) =>
                                    <> {index === 0 && t("Pax Type") + " : "}
                                        {paxType}
                                        {paxTypeCodeArray.length - 1 !== index && ","}
                                        {paxTypeCodeArray.length - 1 === index && isPrivate && `| ${t("Private")}` }
                                        {paxTypeCodeArray.length - 1 === index && " | " + t(fareType)}
                                    </>
                                )}
                            </span>
                            <span>
                                {prixKeys && prixKeys.map((prixKey, index) =>
                                    <>
                                        {index === 0 && " PrixKeys : "}
                                        {prixKey}
                                        {prixKeys.length - 1 !== index && "/"}

                                    </>
                                )}
                            </span>


                        </div>
                        :
                        <span className="label-text">

                        </span>
                    }


                    <button onClick={handleClose}>&times;</button>
                </div>

                <div className="bd-priceSummary-box">


                    <div className="bd-priceSummary">
                        {commitionLabel === "Land Net" &&
                            <div className="ft-notification">
                                <i className="fas fa-info-circle"></i>
                                <span>
                                    {t("Mandatory land component")}
                                </span>
                            </div>
                        }

                        {commitionLabel === "Cruise Net" &&
                            <div className="ft-notification">
                                <i className="fas fa-info-circle"></i>
                                <span>
                                {t("Mandatory cruise sold")}
                                </span>
                            </div>
                        }


                        <div className="bd-priceSummary-details ft-head">

                            <span className="label-text">
                                {t("Fare_Type")}: {t(commitionLabel)}
                                {console.log("commitionLabel",commitionLabel)}
                            </span>
                            {fareType === "Published" &&
                                <span className="label-value">

                                    PUB
                                </span>}
                            <span className="label-net">

                                NET
                            </span>
                        </div>
                        {amountInfo &&
                            amountInfo.map((data, index) => {
                                travlerCount++;

                                totalAmount += data.totalAmount
                                let bookingfee = 0
                                let ticketingfee = 0

                                return (
                                    <div key={index}>

                                        <div className="bd-priceSummary-details sub-head">
                                            <span>
                                                {t("Per")} {data.type === PassengerType.INFANTINLAP ? t("Infant") : t(data.type)}{" "}
                                                {flowFrom === "calculator" && isInternalUserAutheticated &&
                                                <span className="bd-commision-text">
                                                {data.type === PassengerType.INFANTINLAP && infantCommisionPercentage !== undefined && `(${t("Our Commission")}: ${infantCommisionPercentage} % )`}
                                                {data.type === PassengerType.ADULT && adultCommisionPercentage !== undefined && `(${t("Our Commission")} : ${adultCommisionPercentage} % )`}
                                                {data.type === PassengerType.CHILD && childCommisionPercentage !== undefined && `(${t("Our Commission")} : ${childCommisionPercentage} % )`}
                                                </span>
                                                }
                                                {isInternalUserAutheticated && flowFrom !== "calculator" && commitionLabel == "Published Commissionable" &&
                                                    <span className="bd-commision-text">
                                                    {data.type === PassengerType.INFANTINLAP && (costInfantCommission !== undefined || netInfantCommission !== undefined) && `( Commission Airline: ${window.selectedDollarValue} ${costInfantCommission}/  Agency: ${window.selectedDollarValue} ${netInfantCommission} )`}
                                                    {data.type === PassengerType.ADULT && (costAdultCommission !== undefined || netAdultCommission !== undefined) && `( Commission Airline: ${window.selectedDollarValue} ${costAdultCommission}/  Agency: ${window.selectedDollarValue} ${netAdultCommission} )`}
                                                    {data.type === PassengerType.CHILD && (costChildCommission !== undefined || netChildCommission !== undefined) && `( Commission Airline: ${window.selectedDollarValue} ${costChildCommission}/  Agency: ${window.selectedDollarValue} ${netChildCommission} )`}
                                                    </span>
                                                }
                                            </span>

                                        </div>
                                        <div className="bd-priceSummary-details">
                                            <span className="label-text">{t("Base Fare")} </span>
                                            {fareType === "Published" && <span className="label-value">
                                                { `${window.selectedDollarValue} `}{Number(data.baseAmount).toFixed(2)}
                                            </span>}
                                            {fareType === "Published" ?
                                                <span className="label-net">
                                                    { `${window.selectedDollarValue} `} {data.type === PassengerType.INFANTINLAP && (Number(data.sellingBaseAmount)).toFixed(2)}
                                                    {data.type === PassengerType.ADULT && (Number(data.sellingBaseAmount)).toFixed(2)}
                                                    {data.type === PassengerType.CHILD && (Number(data.sellingBaseAmount)).toFixed(2)}
                                                </span> :
                                                <span className="label-net">
                                                    { `${window.selectedDollarValue} `} {data.type === PassengerType.INFANTINLAP && (Number(data.sellingBaseAmount)).toFixed(2)}
                                                    {data.type === PassengerType.ADULT && (Number(data.sellingBaseAmount)).toFixed(2)}
                                                    {data.type === PassengerType.CHILD && (Number(data.sellingBaseAmount)).toFixed(2)}
                                                </span>}
                                        </div>
                                        {Number(data.totalTaxes) !== 0 &&
                                            <div className="bd-priceSummary-details">
                                                <span className="label-text"
                                                >
                                                    {t("Airline taxes & fees")}
                                                </span>
                                                {fareType === "Published" && <span className="label-value">{ `${window.selectedDollarValue} `} {(Number(data.totalTaxes)).toFixed(2)}</span>}

                                                <span className="label-net">{ `${window.selectedDollarValue} `} {(Number(data.totalTaxes)).toFixed(2)}</span>

                                            </div>}

                                        {data.bookingFee !== 0 &&
                                            <div className="bd-priceSummary-details">
                                                <span className="label-text"
                                                >
                                                    {t("Booking fee")}
                                                </span>
                                                <span className="label-value"> </span>
                                                <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(data.bookingFee)).toFixed(2)}</span>


                                            </div>}
                                        {data.ticketingFee !== 0 &&
                                            <div className="bd-priceSummary-details">
                                                <span className="label-text"
                                                >
                                                    {t("Ticketing fee")}
                                                </span>
                                                <span className="label-value"> </span>
                                                <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(data.ticketingFee)).toFixed(2)}</span>
                                            </div>}

                                           

                                        {flowFrom !== "calculator" && (props.fromBookingConfirmation || bookingWithQuote || props.from==="quoteList") ?

                                            <>
                                                {(bookingCode !== "NP" && bookingCode !== "")
                                                    ?

                                                    agencyMarkups && agencyMarkups.length > 0 &&
                                                    <>

                                                        {(adultMarkupValue != 0 && data.type === PassengerType.ADULT) &&
                                                            <div className="bd-priceSummary-details">
                                                                <span className="label-text"
                                                                >
                                                                {t("Markup Applied")}
                                                                </span>
                                                                <span className="label-value"> </span>
                                                                <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(adultMarkupValue)).toFixed(2)}</span>
                                                            </div>

                                                        }

                                                        {childMarkupValue != 0 && data.type === PassengerType.CHILD &&
                                                            <div className="bd-priceSummary-details">
                                                                <span className="label-text"
                                                                >
                                                                {t("Markup Applied")}
                                                                </span>
                                                                <span className="label-value"> </span>
                                                                <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(childMarkupValue)).toFixed(2)}</span>
                                                            </div>


                                                        }

                                                        {infantMarkupValue != 0 && data.type === PassengerType.INFANTINLAP &&
                                                            <div className="bd-priceSummary-details">
                                                                <span className="label-text"
                                                                >
                                                                    {t("Markup Applied")}
                                                                </span>
                                                                <span className="label-value"> </span>
                                                                <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(infantMarkupValue)).toFixed(2)}</span>
                                                            </div>

                                                        }


                                                    </>

                                                    :
                                                    paymentRules && Object.keys(paymentRules) && Object.keys(paymentRules).length > 0 ?
                                                        <>
                                                            {(adultMarkupValue != 0 && data.type === PassengerType.ADULT) &&
                                                                <>
                                                                    <div className="bd-priceSummary-details">
                                                                        <span className="label-text"
                                                                        >
                                                                            {t("Markup Applied")}
                                                                        </span>
                                                                        <span className="label-value"> </span>
                                                                        <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(adultMarkupValue)).toFixed(2)}</span>
                                                                    </div>


                                                                </>
                                                            }


                                                            {childMarkupValue != 0 && data.type === PassengerType.CHILD &&
                                                                <div className="bd-priceSummary-details">
                                                                    <span className="label-text"
                                                                    >
                                                                        {t("Markup Applied")}
                                                                    </span>
                                                                    <span className="label-value"> </span>
                                                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(childMarkupValue)).toFixed(2)}</span>
                                                                </div>

                                                            }

                                                            {infantMarkupValue != 0 && data.type === PassengerType.INFANTINLAP &&
                                                                <div className="bd-priceSummary-details">
                                                                    <span className="label-text"
                                                                    >
                                                                        {t("Markup Applied")}
                                                                    </span>
                                                                    <span className="label-value"> </span>
                                                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(infantMarkupValue)).toFixed(2)}</span>
                                                                </div>

                                                            }

                                                        </> :
                                                        agencyMarkups && agencyMarkups.length > 0 &&
                                                        <>

                                                            {(adultMarkupValue != 0 && data.type === PassengerType.ADULT) &&
                                                                <div className="bd-priceSummary-details">
                                                                    <span className="label-text"
                                                                    >
                                                                        {t("Markup Applied")}
                                                                    </span>
                                                                    <span className="label-value"> </span>
                                                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(adultMarkupValue)).toFixed(2)}</span>
                                                                </div>

                                                            }

                                                            {childMarkupValue != 0 && data.type === PassengerType.CHILD &&
                                                                <div className="bd-priceSummary-details">
                                                                    <span className="label-text"
                                                                    >
                                                                        {t("Markup Applied")}
                                                                    </span>
                                                                    <span className="label-value"> </span>
                                                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(childMarkupValue)).toFixed(2)}</span>
                                                                </div>


                                                            }

                                                            {infantMarkupValue != 0 && data.type === PassengerType.INFANTINLAP &&
                                                                <div className="bd-priceSummary-details">
                                                                    <span className="label-text"
                                                                    >
                                                                        {t("Markup Applied")}
                                                                    </span>
                                                                    <span className="label-value"> </span>
                                                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(infantMarkupValue)).toFixed(2)}</span>
                                                                </div>

                                                            }

                                                        </>

                                                }

                                            </>

                                            :
                                            <>
                                                {paymentRules && Object.keys(paymentRules) && Object.keys(paymentRules).length > 0 && flowFrom !== "calculator" &&
                                                    <>
                                                        {(adultMarkupValue != 0 && data.type === PassengerType.ADULT) && !markupCheckFlagForAdult &&
                                                            <>
                                                             <div className="bd-priceSummary-details">
                                                                    <span className="label-text"
                                                                    >
                                                                        {t("Markup Applied")}
                                                                    </span>
                                                                    <span className="label-value"> </span>
                                                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(adultMarkupValue)).toFixed(2)}</span>
                                                                </div>
                                                               

                                                            </>
                                                        }


                                                        {childMarkupValue != 0 && data.type === PassengerType.CHILD && !markupCheckFlagForChild &&
                                                         <div className="bd-priceSummary-details">
                                                         <span className="label-text"
                                                         >
                                                             {t("Markup Applied")}
                                                         </span>
                                                         <span className="label-value"> </span>
                                                         <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(childMarkupValue)).toFixed(2)}</span>
                                                     </div>
                                                            
                                                        }

                                                        {infantMarkupValue != 0 && data.type === PassengerType.INFANTINLAP && !markupCheckFlagForInfant &&
                                                         <div className="bd-priceSummary-details">
                                                         <span className="label-text"
                                                         >
                                                             {t("Markup Applied")}
                                                         </span>
                                                         <span className="label-value"> </span>
                                                         <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(infantMarkupValue)).toFixed(2)}</span>
                                                     </div>
                                                           
                                                        }
                                                    </>
                                                }
                                            </>
                                        }

                                        {props.flowFrom === "calculator" ?
                                            <div className="bd-priceSummary-details ft-total">
                                                <span className="label-text">
                                                    Total

                                                </span>
                                                {fareType === "Published" &&
                                                    <span className="label-value">
                                                        { `${window.selectedDollarValue} `} {(Number(data.supplierTotalAmount)).toFixed(2)}
                                                    </span>
                                                }

                                                {fareType === "Published" ?
                                                    <span className="label-net">

                                                        { `${window.selectedDollarValue} `}
                                                        {data.type === PassengerType.INFANTINLAP &&
                                                            (Number(data.totalAmount)).toFixed(2)

                                                        }
                                                        {data.type === PassengerType.ADULT &&
                                                            ((Number(data.totalAmount)).toFixed(2))

                                                        }
                                                        {data.type === PassengerType.CHILD &&
                                                            (Number(data.totalAmount)).toFixed(2)

                                                        }

                                                    </span> :
                                                    <span className="label-net">

                                                        { `${window.selectedDollarValue} `}

                                                        {data.type === PassengerType.INFANTINLAP &&
                                                            (Number(data.totalAmount)).toFixed(2)

                                                        }
                                                        {data.type === PassengerType.ADULT &&

                                                            ((Number(data.totalAmount)).toFixed(2))

                                                        }
                                                        {data.type === PassengerType.CHILD &&
                                                            (Number(data.totalAmount)).toFixed(2)

                                                        }

                                                    </span>}
                                            </div>
                                            :

                                            <div className="bd-priceSummary-details ft-total">
                                                <span className="label-text">
                                                    {t("Total")}

                                                </span>
                                                {fareType === "Published" &&
                                                    <span className="label-value">
                                                        { `${window.selectedDollarValue} `} {(Number(data.supplierTotalAmount)).toFixed(2)}
                                                    </span>
                                                }

                                                {fareType === "Published" ?
                                                    <span className="label-net">

                                                        { `${window.selectedDollarValue} `}
                                                        {data.type === PassengerType.INFANTINLAP &&
                                                            (Number(data.sellingBaseAmount) + Number(data.totalTaxes) + Number(data.bookingFee) + Number(data.ticketingFee) + Number(infantMarkupValue)).toFixed(2)

                                                        }
                                                        {data.type === PassengerType.ADULT &&
                                                            ((Number(data.sellingBaseAmount) + Number(data.totalTaxes) + Number(data.bookingFee) + Number(data.ticketingFee) + Number(adultMarkupValue)).toFixed(2))

                                                        }
                                                        {data.type === PassengerType.CHILD &&
                                                            (Number(data.sellingBaseAmount) + Number(data.totalTaxes) + Number(data.bookingFee) + Number(data.ticketingFee) + Number(childMarkupValue)).toFixed(2)

                                                        }

                                                    </span> :
                                                    <span className="label-net">

                                                        { `${window.selectedDollarValue} `}

                                                        {data.type === PassengerType.INFANTINLAP &&
                                                            (Number(data.sellingBaseAmount) + Number(data.totalTaxes) + Number(data.bookingFee) + Number(data.ticketingFee) + Number(infantMarkupValue)).toFixed(2)

                                                        }
                                                        {data.type === PassengerType.ADULT &&

                                                            ((Number(data.sellingBaseAmount) + Number(data.totalTaxes) + Number(data.bookingFee) + Number(data.ticketingFee) + Number(adultMarkupValue)).toFixed(2))

                                                        }
                                                        {data.type === PassengerType.CHILD &&
                                                            (Number(data.sellingBaseAmount) + Number(data.totalTaxes) + Number(data.bookingFee) + Number(data.ticketingFee) + Number(childMarkupValue)).toFixed(2)

                                                        }

                                                    </span>}
                                            </div>
                                        }

                                    </div>
                                )

                            })}

                    </div>

                </div>

            </div>
        </Dialog>
    );
}

export default FareBreakDown;

