import React, { useEffect, useState , createRef } from 'react';
import BookingSubHead from './BookingSubHead';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from "react-redux";
import MarkupIcon from "../../Assets/markup-icon.svg";
import { setAdultMarkupValue, setChildMarkupValue, setInfantMarkupValue } from "../../appStore/actionCreators/searchForm";
import { setApplyMarkupButton, setItenaryData, setMarkupApplied, setMarkupButtonCheck, setMarkupLoader, setMarkupPopUp, setPaymentRules, setPaymentRulesForCheque } from "../../appStore/actionCreators/flightBooking"
import { getPaymentRules } from "../../appStore/thunks/getPaymentRules"
import { bookingStatus, fareTypeConstant, PassengerType } from '../../utils/constant';
import {msg} from "../../utils/utility/MessagesContainer"
import Dialog from "@material-ui/core/Dialog";
import SpinnerLoader from '../common/spinner';
import { getApplyConfiguredBookingAmount } from '../../appStore/thunks/getApplyConfiguredBookingAmount';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getPnrDetails } from '../../appStore/thunks/getPnrDetails';
import i18n from '../../LangConfig';
import { airlineTransitionDetails, maxMarkupAmountLimitPerPaxType } from '../../utils/utility/commenFunctions';
import  DefaultMarkup  from './DefaultMarkup';
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { getEquivalentPublishFare } from "../../appStore/thunks/getEquivalentPublishFare";
import { getEquivalentPublishFareForPnr } from '../../appStore/thunks/getEquivalentPublishFareForPnr';

toast.configure()
function Markup(props) {
    
    const { t } = useTranslation(); 
    const lang = useSelector( (state) => state.lang.lang)
    const dispatch = useDispatch();
    const scrollDiv = createRef();

    let adultsMarkup = useSelector((state) => state.searchForm.adultMarkupValue);
    let childMarup = useSelector((state) => state.searchForm.childMarkupValue);
    let infantMarkup = useSelector((state) => state.searchForm.infantMarkupValue);
    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
    const markupBtnCheck = useSelector((state) => state.flightBooking.marKupButtonCheck)
    const markupBtnCount = useSelector((state) => state.flightBooking.marKupButtonCount)
    const [adultMarkupError,setAdultErrorMarup] = useState("")
    const [childMarkUpValueError,setChildErrorMarup] = useState("")
    const [infantMarkUpValueError,setInfantErrorMarup] = useState("")
    const [adultMarkup, setMarkUpValue] = useState(adultsMarkup>0?Number(adultsMarkup):0.00)
    const [childMarkUpValue, setChildMarkUpValue] = useState(childMarup>0?Number(childMarup):0.00)
    const [infantMarkUpValue, setInfantMarkUpValue] = useState(infantMarkup>0?Number(infantMarkup):0.00)
    const [open, setOpen] = React.useState(false);
    const [isLoader, setLoder] = useState(false);
    const [saveMarkupErrorMsg,setMarkupErrorMsg] = useState("");
    // const [markupPopUp, setMarkupPopUp] = React.useState(false);
    const [disableButton, setDisableButton] = useState(false)

    useEffect(() =>{i18n.changeLanguage(lang)},[lang])
    useEffect(() => {
        if(props.from !=="confirmation"){
           // dispatch(setMarkupApplied(false))
        } else{
            let adultData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.ADULT)
            let childData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.CHILD)
            let infantdata = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.INFANTINLAP)
            let adultAmount = adultData && adultData[0] ? adultData[0].perPaxAmount &&adultData[0].perPaxAmount.amount :0
            let childAmount = childData && childData[0] ? childData[0].perPaxAmount &&childData[0].perPaxAmount.amount :0
            let infantAmount =infantdata && infantdata[0] ? infantdata[0].perPaxAmount &&infantdata[0].perPaxAmount.amount :0
          

            dispatch(setMarkupApplied(true))
            dispatch(setAdultMarkupValue(adultsMarkup?adultsMarkup:adultAmount))
            dispatch(setChildMarkupValue(childMarup?childMarup:childAmount))
            dispatch(setInfantMarkupValue(infantMarkup?infantMarkup:infantAmount))
            setMarkUpValue(adultsMarkup ? `${adultsMarkup}`: `${adultAmount}`)
            setChildMarkUpValue(childMarup?`${childMarup}`:`${childAmount}`)
            setInfantMarkUpValue(infantMarkup?`${infantMarkup}`:`${infantAmount}`)

        }
      
    }, [])

    useEffect(()=>{
        setMarkUpValue(adultsMarkup);
        setChildMarkUpValue(childMarup);
        setInfantMarkUpValue(infantMarkup);
    },[adultsMarkup,childMarup,infantMarkup])

// AFTER GET BOOKING DETAILS UPDATING MARKUP DETAILS IN STORE
    useEffect(() => {
          if(agencyMarkups && agencyMarkups.length > 0){
            let adultData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.ADULT)
            let childData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.CHILD)
            let infantdata = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.INFANTINLAP)
            let adultAmount = adultData && adultData[0] ? adultData[0].perPaxAmount &&adultData[0].perPaxAmount.amount :""
            let childAmount = childData && childData[0] ? childData[0].perPaxAmount &&childData[0].perPaxAmount.amount :""
            let infantAmount =infantdata && infantdata[0] ? infantdata[0].perPaxAmount &&infantdata[0].perPaxAmount.amount :""
          
            dispatch(setAdultMarkupValue(adultAmount !== "" ? adultAmount : adultsMarkup))
            dispatch(setChildMarkupValue(childAmount !== "" ? childAmount : childMarup))
            dispatch(setInfantMarkupValue(infantAmount !== "" ? infantAmount : infantMarkup))
            setMarkUpValue(adultAmount !== "" ? `${adultAmount}`: `${adultsMarkup}`)
            setChildMarkUpValue(childAmount !== "" ?`${childAmount}`:`${childMarup}`)
            setInfantMarkUpValue(infantAmount !== "" ?`${infantAmount}`:`${infantMarkup}`)
        }
    }, [bookingData])
    

    useEffect(()=>{

        if(bookingWithQuote && (adultsMarkup !== 0 || childMarup !== 0 || infantMarkup !== 0)){

            dispatch(setMarkupApplied(true))
            dispatch(setAdultMarkupValue(adultsMarkup))
            dispatch(setChildMarkupValue(childMarup))
            dispatch(setInfantMarkupValue(infantMarkup))
            setMarkUpValue(adultsMarkup)
            setChildMarkUpValue(childMarup)
            setInfantMarkupValue(infantMarkup)
            

        }else if(props.from !=="confirmation"){
           // dispatch(setMarkupApplied(false))
        }

    },[bookingWithQuote])

    // useEffect(()=>{
        
    //     if(markupBtnCheck === "saved"){
    //        scrollSmoothHandler() 
    //        setMarkupPopUp(true)         
    //     }else{
    //         setMarkupPopUp(false)
    //     } 
    // },[markupBtnCheck,markupBtnCount])

    let priceValue = useSelector((state) => state.flightBooking.bookingItenaries);
    const agentProfile = useSelector((state) => state.agentProfile);
    const { orgId, agencyId, agencyGroupId} = agentProfile
    
    let fareId = useSelector((state) => state.searchForm.bookingFareId);
    const markupStatus = useSelector((state) => state.flightBooking.markupStatus);
    const flightDetail = useSelector((state) => state.flightBooking);
    const getPnrResult = useSelector((state) => state.flightBooking.getPnrResult);
    const quoteDetails = useSelector((state) => state.savedQuotes.quoteDetails);
    const quoteId = quoteDetails && quoteDetails.quoteId;
    const quoteFareId = quoteDetails && quoteDetails.fareDetails && quoteDetails.fareDetails.itineraryFare && quoteDetails.fareDetails.itineraryFare.id;

    let adultsCount 
    let childCount 
    let infantCount
  
    const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
    const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
    const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
    const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
    const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
    const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
    const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
    const equivalentFareValue = useSelector((state) => state.flightBooking.equivalentFareValue);
    const markupLoader = useSelector((state) => state.flightBooking.markupLoader);
    const providerId = useSelector((state) => state.searchForm.providerId);
    const agencyDetails = useSelector((state) => state.agentProfile.agencyDetails);
    const agentDetails = useSelector((state) => state.agentProfile.agentDetails);
    const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
    const paymentRules = useSelector((state) => state.flightBooking.paymentRules);
    const markupPopUpStore = useSelector((state)=>state.flightBooking.markupPopUp)
    let repriceQuote = useSelector((state) => state.savedQuotes.repriceQuote);
    const equivalentFareLoader = useSelector((state) => state.flightBooking.equivalentFareLoader);
    let PassengerWiseTransactionsDetails = paymentRules && paymentRules.PassengerWiseTransactionsDetails;
    let maximumAllowedAgencyMarkups = paymentRules?.maximumAllowedAgencyMarkups;
    let markupLimitAmountPerAdult = 0;
    let markupLimitAmountPerChild = 0;
    let markupLimitAmountPerInfant = 0;
    let ccAllowed = paymentRules && paymentRules?.itineraryFare?.applicableContract?.cCAllowed;
    let transactionDetails = paymentRules && paymentRules?.transactionsDetails;
    let airlineTransactionCheck = transactionDetails && transactionDetails.filter(val => val.merchant === "AirLine");
    let airlineTransactionAmount = airlineTransactionCheck && airlineTransactionCheck.length > 0 ? airlineTransactionCheck[0].totalAmount.amount : 0;
    if(maximumAllowedAgencyMarkups){
    for(let index = 0; index < maximumAllowedAgencyMarkups.length; index++){   
        let type = maximumAllowedAgencyMarkups[index] && maximumAllowedAgencyMarkups[index].paxType;
        if((type === PassengerType.ADULT || type === PassengerType.CHILD) && maximumAllowedAgencyMarkups[index].amount?.amount == 0){
            markupLimitAmountPerAdult = 0
            markupLimitAmountPerChild = 0
            markupLimitAmountPerInfant = 0
            break;
        }    
        if (type === PassengerType.ADULT) {
            markupLimitAmountPerAdult = maximumAllowedAgencyMarkups[index].amount?.amount
        }
        if (type === PassengerType.CHILD) {
            markupLimitAmountPerChild = maximumAllowedAgencyMarkups[index].amount?.amount
        }
        if (type === PassengerType.INFANTINLAP) {
            markupLimitAmountPerInfant = maximumAllowedAgencyMarkups[index].amount?.amount
        }
        
    }
    } else {
        if (transactionDetails && transactionDetails.length == 2) {
            markupLimitAmountPerAdult = 0
            markupLimitAmountPerChild = 0
            markupLimitAmountPerInfant = 0
        }
    }

    let transitionFlag = true;
      
    // logic for language selection
    

    const scrollSmoothHandler = () => {
        scrollDiv.current.scrollIntoView({ behavior: "smooth" })  
    };
   
    let fareType = priceValue && priceValue.type
    let fareDetails = repriceQuote && repriceQuote.fareDetails
    let quoteDetail = fareDetails && fareDetails.itineraryFare
    if (bookingWithQuote) {
        priceValue = quoteDetail
        fareType = quoteDetail && quoteDetail.type
    }
    const faresPerTravellers = priceValue && priceValue.paxFares; 
    
    let paxFareData = equivalentFareValue && equivalentFareValue.paxFares;
    let paxTypeCodeArray = []

    faresPerTravellers && faresPerTravellers.map(data => {
        let paxTypeCode = data && data.passengerDetails && data.passengerDetails.code
        
        if (!paxTypeCodeArray.includes(paxTypeCode)) {
            paxTypeCodeArray.push(paxTypeCode)
        }
    })
    
    let amountInfo= []
    let paxTypeAmount = paxFareData

        if (pnrFlag) {
            adultsCount = pnrAdultsCount
            childCount = pnrChildCount
            infantCount = pnrInfantCount
        } else {
            adultsCount = searchAdultCOunt
            childCount = searchChildCount
            infantCount = searchInfantCount
        }
       
        paxTypeAmount && paxTypeAmount.map((paxType, index) => {
            let passengerDetails = paxType.passengerDetails;
            let type = passengerDetails && passengerDetails.type;
            let quantity = passengerDetails && passengerDetails.quantity
            let totalAmount = paxType.totalAmount
            let baseAmount = paxType.baseAmount
            let supplierBaseAmount = paxType.supplierBaseAmount
            let totalTaxes = paxType.totalTaxes
            let taxes = paxType.taxes
            // let paxTypeCode = paxType.passengerDetails.code
            // if (!paxTypeCodeArray.includes(paxTypeCode)) {
            //     paxTypeCodeArray.push(paxTypeCode)
            // }


            let ispreset = amountInfo.filter(obj => obj.type === type)

            if (ispreset.length === 0) {
                let passenger = {
                    type: type,
                    quantity: quantity,
                    totalAmount: totalAmount.amount,
                    baseAmount: baseAmount.amount,
                    totalTaxes: totalTaxes.amount,
                    taxes: taxes,
                    supplierBaseAmount: supplierBaseAmount && supplierBaseAmount.amount
                }
                amountInfo.push(passenger)
            }
        })

  
    if (pnrFlag) {
        adultsCount = pnrAdultsCount
        childCount = pnrChildCount
        infantCount = pnrInfantCount
    } else {
        adultsCount = searchAdultCOunt
        childCount = searchChildCount
        infantCount = searchInfantCount
    }

    let passengetCount = adultsCount + childCount + infantCount;
    const currency = priceValue && priceValue.baseAmount && priceValue.baseAmount.currency
    const contractdetails = priceValue && priceValue.applicableContract && priceValue.applicableContract.passBackCommission
    
    const recordLocator = flightDetail && flightDetail.pnr;
    let agencyMarkups = bookingData&&bookingData.agencyMarkups
    const bookingCode = flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code
    let errorMsg =  t('114')

    let arr = []
    let passengerRef = []

    const agencyData =
    {
      code: agencyDetails?.adAgencyCode,
      name: agencyDetails?.adTravelAgencyName,
      networkId: agencyDetails?.adNetworkId,
      address: {
        line1: agencyDetails?.adAddressLine1,
        line2: agencyDetails?.adAddressLine2,
        city: {
          name: agencyDetails?.adCity,
          // code: agencyDetails.adCity
        },
        state: {
          // name: agencyDetails.provinceOrState,
          code: agencyDetails?.provinceOrState
        },
        country: {
          // name: agencyDetails.country,
          code: agencyDetails?.country
        },
        postalCode: agencyDetails?.adPostalCode,
        addressType: "Unspecified" //Unspecified,Home,Business,Office,Billing,Residential,Destination
      },
      phone: {
        number: agencyDetails?.adNetworkId,
        type: "Unknown" //Unknown,Fax,Home,Business,Agency,Phone,Mobile
      },
      agencyGroupCode: agencyDetails?.consortiumId
    };
  
    const travelAgent = {
        firstName: isInternalUserAutheticated ? agentDetails?.firstName : agencyDetails?.firstName,
        lastName: isInternalUserAutheticated ? agentDetails?.lastName : agencyDetails?.lastName,
        userName: isInternalUserAutheticated ? agentDetails?.username : agencyDetails?.username
    };
    let markupValuePerPassenger = {
        adult : adultsMarkup,
        child : childMarup,
        infant : infantMarkup
    }
    
    // if((infantCount > 0 && markupLimitAmountPerInfant == 0) && transactionDetails?.length > 1){
    //     transitionFlag = false;
    // }

    for (let i = 1; i <= passengetCount; i++) {
        if (i <= adultsCount) {
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.ADULT
                },
            ];
            passengerRef.push(`${i}`)
        } else if (i > adultsCount && i <= adultsCount + childCount) {
            let j = i - adultsCount;
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.CHILD
                },
            ];
            passengerRef.push(`${i}`)
        } else {
            let j = i - (adultsCount + childCount);
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.INFANTINLAP,
                },
            ];
            passengerRef.push(`${i}`)
        }
    }

    

    const addMarkUpValueInfo = (e) => {
        
        let input = parseInt(String(e.target.value).trim(),10)
        if(input < 0){
            setMarkUpValue("")
        } else{
            if(input.length > 3){
                // setMarkUpValue(adultMarkup)
                setMarkUpValue(String(input))
            } else {
                setMarkUpValue(String(input))
            }
           
            let value = e.target.value
            
           // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
            if(value.trim().length > 0){
                setAdultErrorMarup("")
                dispatch(setApplyMarkupButton(false))
            } else{
                setAdultErrorMarup(errorMsg)
            }
         }
         
    }

    const addChildMarkUpValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        if(input < 0){
            setChildMarkUpValue("")
        }
         else {
            if(input?.length > 3){
                // setChildMarkUpValue(childMarkUpValue)
                setChildMarkUpValue(String(input))
            } else {
                setChildMarkUpValue(String(input))
            }
            let value = e.target.value
            
           // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
            if(value.trim().length > 0){
                setChildErrorMarup("")
                dispatch(setApplyMarkupButton(false))
            } else{
                setChildErrorMarup(errorMsg)
            }  

        }
       
    }

    const addInfantMarkUpValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        if(input < 0){
            setInfantMarkUpValue("")
        }
        else {
          if(input?.length > 3){
            // setInfantMarkUpValue(infantMarkUpValue)
            setInfantMarkUpValue(String(input))
        } else {
            setInfantMarkUpValue(String(input))
        }
        let value = e.target.value
            
        // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
         if(value.trim().length > 0){
              setInfantErrorMarup("")
              dispatch(setApplyMarkupButton(false))
          } else{
              setInfantErrorMarup(errorMsg)
          }
        }  
        
    }

    const handleMarkupPopUpClose = () => {
        // setMarkupPopUp(false)
        setMarkUpValue(adultsMarkup)
        setChildMarkUpValue(childMarup)
        setInfantMarkUpValue(infantMarkup)
        dispatch(setMarkupButtonCheck(false))
        dispatch(setMarkupApplied(true))
        
      };
    
      const handleMarkupSave = () => {
        handleMarkUpSubmit()
        // setMarkupPopUp(false)
        dispatch(setMarkupButtonCheck(false))
        dispatch(setMarkupApplied(true))
      };


    const handleMarkUpSubmit = async() => {
        if(adultMarkupError !== "" || childMarkUpValueError !== "" || infantMarkUpValueError !== "") return 
        setLoder(true)
        dispatch(setMarkupLoader(true))
        dispatch(setMarkupButtonCheck(false))
        if(adultMarkup.toString() && adultMarkup.toString().trim().length===0 && adultsCount>0){
           return setAdultErrorMarup(errorMsg)
        }
        if(childMarkUpValue.toString() && childMarkUpValue.toString().trim().length===0 && childCount>0){
            return setChildErrorMarup(errorMsg)
            
        }
        if(infantMarkUpValue.toString() && infantMarkUpValue.toString().trim().length===0 && infantCount>0){
            return setInfantErrorMarup(errorMsg)
            
        }
       
        let agencyArr = []
        if (adultsCount > 0) {
            let obj = {
                paxType: PassengerType.ADULT,
                quantity: adultsCount,
                perPaxAmount: {
                    amount: adultMarkup ? adultMarkup : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }
        if (childCount > 0) {
            let obj = {
                paxType: PassengerType.CHILD,
                quantity: childCount,
                perPaxAmount: {
                    amount: childMarkUpValue ? childMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        if (infantCount > 0) {
            let obj = {
                paxType: PassengerType.INFANTINLAP,
                quantity: infantCount,
                perPaxAmount: {
                    amount: infantMarkUpValue ? infantMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        // SAVE AGENCY MARKUP 
        let saveAgencySucessFlag = false
        if(props.from === "confirmation"){
            let saveMarkupReq    = {
                organizationId: orgId,
                agencyId: agencyId,
                agencyGroupId: agencyGroupId,
                passengers: arr,
                recordLocator: recordLocator,
                agencyMarkups: agencyArr,
               // defaultAppliedPaymentRules:defaultAppliedPaymentRules
              }
               let result //=  await dispatch(getSaveMarkup(saveMarkupReqPayload));
               if(result === "error"){
                    dispatch(setMarkupLoader(false))
                    dispatch(setMarkupApplied(false))
                    saveAgencySucessFlag = false;
                    toast.error(t(`129`), { position: toast.POSITION.TOP_CENTER, autoClose: true, progressStyle: { "transform-origin": "right" } });
                }
                if(result && result.status === "Success"){
                    saveAgencySucessFlag = true;
                }
                if(result && result.status === "Failure"){
                    let errorMsg = result && result.error && result.error.message
                    setMarkupErrorMsg(errorMsg);
                    dispatch(setMarkupLoader(false))
                    dispatch(setMarkupApplied(false))
                    saveAgencySucessFlag = false;
                    toast.error(t(`132`), { position: toast.POSITION.TOP_CENTER, autoClose: true, progressStyle: { "transform-origin": "right" } });
               }
        }else{
            saveAgencySucessFlag = true;
        }
       
       if(saveAgencySucessFlag){
        let reqPayload={}
        if(props.from==="confirmation"){
            reqPayload = {
                recordLocator: recordLocator,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "CreditCard"
                }]
            }

        } else{
            reqPayload = {
                fareId: fareId,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "CreditCard"
                }]
            }
        }
       let resultOfPaymentRulesByCard =  await dispatch(getPaymentRules(reqPayload,"CreditCard","fromMarkup"))
       let resultOfPaymentRulesByCheque;
       if(resultOfPaymentRulesByCard){
        if(props.from==="confirmation"){
            reqPayload = {
                recordLocator: recordLocator,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "Cheque"
                }]
            }

        } else{
            reqPayload = {
                fareId: fareId,
                fareType: fareType,
                passengers: arr,
                agencyMarkups: agencyArr,
                payments: [{
                    passengerRefs: passengerRef,
                    type: "Cheque"
                }]
            }
        }

        resultOfPaymentRulesByCheque = await dispatch(getPaymentRules(reqPayload,"Cheque","fromMarkup"))
       }

       //    let passengerWiseTransactionsDetails = resultOfPaymentRulesByCard && resultOfPaymentRulesByCard.PassengerWiseTransactionsDetails

        dispatch(setMarkupLoader(false))
        dispatch(setMarkupApplied(true))
        dispatch(setPaymentRules(resultOfPaymentRulesByCard)) 
        dispatch(setPaymentRulesForCheque(resultOfPaymentRulesByCheque))
        dispatch(setAdultMarkupValue(adultMarkup))
        dispatch(setChildMarkupValue(childMarkUpValue))
        dispatch(setInfantMarkupValue(infantMarkUpValue))
        setLoder(false)
        dispatch(setMarkupLoader(false))
        // setMarkupPopUp(false)
        if(bookingCode === ""){
            dispatch(setAdultMarkupValue(adultMarkup))
            dispatch(setChildMarkupValue(childMarkUpValue))
            dispatch(setInfantMarkupValue(infantMarkUpValue))
            dispatch(setPaymentRules(resultOfPaymentRulesByCard)) 
            dispatch(setPaymentRulesForCheque(resultOfPaymentRulesByCheque))
        }
      }  

    }

    const editMarkup = () => {
        dispatch(setMarkupApplied(false))
        dispatch(setMarkupButtonCheck(true))
    }

    const blockInvalidChar = e => {
        ["e", "E", "+", "-" , "@"].includes(e.key) && e.preventDefault()
        if(e.key==='.'){
            e.preventDefault();
        }
    }
    
    const handleEquivalentFare = async() => {
        setOpen(true)
        if(Object.keys(equivalentFareValue).length === 0 && equivalentFareValue.constructor === Object){
            if(bookingWithQuote){
                let res = await dispatch(getEquivalentPublishFare(quoteFareId,"quoteFlow",quoteId))
            }else if(props?.from == "booking"){
                let res=await dispatch(getEquivalentPublishFare(fareId));
            }else if(props.from==="confirmation") {
                let payload={
                    "organizationId": orgId,
                    "agencyId": agencyId,
                    "agencyGroupId": agencyGroupId,
                    "userId": getPnrResult?.userId,
                    "recordLocator": recordLocator,   
                    "bookingSource": getPnrResult?.providerName,
                    "agentId": travelAgent?.userName,
                    "agentName": travelAgent?.userName
                }
                let res = await dispatch(getEquivalentPublishFareForPnr(payload))
            }
        }
    }
    const handleEquivalentFareClose = () => {      
        setOpen(false)
        // dispatch(getEquivalentPublishFare(fareId))
    }

    let markupAppliedFlag = true;
    if(adultMarkup == 0 && childMarkUpValue == 0 && infantMarkUpValue == 0){
        markupAppliedFlag = false;
    }
    let maxMarkupLimit = maxMarkupAmountLimitPerPaxType(PassengerWiseTransactionsDetails)
    let adultMaxMarkupLimit = maxMarkupLimit && maxMarkupLimit.forAdultMaxLimit;
    let childMaxMarkupLimit = maxMarkupLimit && maxMarkupLimit.forChildMaxLimit;
    let infantMaxMarkupLimit = maxMarkupLimit && maxMarkupLimit.forInfantMaxLimit;  
let totalAmount;
let paxTypeConatinsArr = ["JCB","ITX","PFA","PV"]
let paxTypeFlag = paxTypeConatinsArr.some(ai => paxTypeCodeArray.includes(ai));

const handleApplyMarkUp = async(requestPayload) => {
    let agencyArr = []
        if (adultsCount > 0) {
            let obj = {
                paxType: PassengerType.ADULT,
                quantity: adultsCount,
                perPaxAmount: {
                    amount: adultMarkup ? adultMarkup : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }
        if (childCount > 0) {
            let obj = {
                paxType: PassengerType.CHILD,
                quantity: childCount,
                perPaxAmount: {
                    amount: childMarkUpValue ? childMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        if (infantCount > 0) {
            let obj = {
                paxType: PassengerType.INFANTINLAP,
                quantity: infantCount,
                perPaxAmount: {
                    amount: infantMarkUpValue ? infantMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }
    requestPayload["fareType"] = fareType
    requestPayload["passengers"] = arr
    requestPayload["agencyMarkups"] = agencyArr
    requestPayload["payments"] = [{
                    passengerRefs: passengerRef,
                    type: "CreditCard"
                }]
    let result = await dispatch(getApplyConfiguredBookingAmount(requestPayload, props.from));
    if (result) {
        setLoder(false)
        dispatch(setMarkupLoader(false))
        
        if ((!result.hasOwnProperty('error'))) {
            dispatch(setAdultMarkupValue(adultMarkup))
            dispatch(setChildMarkupValue(childMarkUpValue))
            dispatch(setInfantMarkupValue(infantMarkUpValue))
        }else{
            dispatch(setMarkupPopUp(false))
            setMarkUpValue(adultsMarkup)
            setChildMarkUpValue(childMarup)
            setInfantMarkUpValue(infantMarkup)
        }
    }
    return result
}

const handleSaveMarup = () => {
    if(adultMarkupError !== "" || childMarkUpValueError !== "" || infantMarkUpValueError !== "") return 
        setLoder(true)
        dispatch(setMarkupLoader(true))
        dispatch(setMarkupButtonCheck(false))
        if(adultMarkup.toString() && adultMarkup.toString().trim().length===0 && adultsCount>0){
           return setAdultErrorMarup(errorMsg)
        }
        if(childMarkUpValue.toString() && childMarkUpValue.toString().trim().length===0 && childCount>0){
            return setChildErrorMarup(errorMsg)
            
        }
        if(infantMarkUpValue.toString() && infantMarkUpValue.toString().trim().length===0 && infantCount>0){
            return setInfantErrorMarup(errorMsg)
            
        }
    let agencyArr = []
        if (adultsCount > 0) {
            let obj = {
                paxType: PassengerType.ADULT,
                quantity: adultsCount,
                perPaxAmount: {
                    amount: adultMarkup ? adultMarkup : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }
        if (childCount > 0) {
            let obj = {
                paxType: PassengerType.CHILD,
                quantity: childCount,
                perPaxAmount: {
                    amount: childMarkUpValue ? childMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        if (infantCount > 0) {
            let obj = {
                paxType: PassengerType.INFANTINLAP,
                quantity: infantCount,
                perPaxAmount: {
                    amount: infantMarkUpValue ? infantMarkUpValue : 0,
                    currency: currency
                }
            }
            agencyArr.push(obj)
        }

        let saveMarkupReq    = {
            organizationId: orgId,
            agencyId: agencyId,
            agencyGroupId: agencyGroupId,
            passengers: arr,
            agencyMarkups: agencyArr,
           // defaultAppliedPaymentRules:defaultAppliedPaymentRules
        }

    return saveMarkupReq
}

    useEffect(() => {

        if (adultMarkupError != "" || childMarkUpValueError != "" || infantMarkUpValueError != "") {
            setDisableButton(true);
        }
        if (adultMarkupError == "" && childMarkUpValueError == "" && infantMarkUpValueError == "") {
            setDisableButton(false);
        }
    }, [adultMarkupError, childMarkUpValueError, infantMarkUpValueError])

    return (
        <>
        {props.show ? 
            <div ref={scrollDiv} >

            <div className="bd-configProfit">
                <BookingSubHead 
                title={t("Add/Modify Agency Markup")} 
                link={false} 
                imgSrc={MarkupIcon} 
                addLink={props.from==="confirmation" ? (markupStatus && !markupLoader && (bookingCode==="NP" || bookingCode==="SC") && t("Edits")): (markupStatus && !markupLoader && t("Edits"))} 
                // status={markupStatus && markupAppliedFlag && !markupLoader && t("Markup Applied") + "!"} 
                equivalentFare = {paxTypeFlag && fareType === fareTypeConstant.NET && handleEquivalentFare}
                editMarkup={editMarkup} />

                <div className="configProfit-row">
                    {fareType === fareTypeConstant.NET && ccAllowed && airlineTransactionAmount > 0 && transitionFlag &&
                     <div className="bd-alertBox">
                       <i>!</i>
                        {t("amount higher than the markup amount")}
                      </div>
                    }
                    {fareType === fareTypeConstant.PUB && ccAllowed && airlineTransactionAmount > 0 &&
                     <div className="bd-alertBox">
                       <i>!</i>
                        {t("Adding a markup amount")}
                      </div>
                    }                   
                    {saveMarkupErrorMsg !== "" && saveMarkupErrorMsg !== undefined &&
                     <div className='payment-error-msg-wrapper'>
                        <div className="seat-udate-status">
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span>{saveMarkupErrorMsg}</span>
                        </div>
                    </div>
                    } 
                    <div className="row space-between">
                    {adultsCount !== 0 &&
                        <div className="configProfit-col" >
                            <div className="title">{t("Per Adult")}  ( X {adultsCount} )</div>
                            <div className="configProfit-input pt-3">
                                {fareType === "Published" && contractdetails && contractdetails.amountPerAdult && contractdetails.amountPerAdult.amount > 0 &&
                                    // <div className="configProfit-input-label">
                                    //     Current Markup<span>{contractdetails?.amountPerAdult?.amount}</span>
                                    // </div>
                                    <div className="bd-pi-input">
                                    <TextField
                                    id="currentMarkup"
                                    label={t("Current Commission")}
                                    variant="outlined"
                                    value={contractdetails?.amountPerAdult?.amount}
                                    disabled={true}
                                    
                                />
                                </div>
                                    }

                                <div className={lang=="en"?"bd-pi-input":"bd-pi-input-fr"}>
                                    
                                    <TextField
                                        id="additionalMarkup1"
                                        label={t("Additional Markup")}
                                        variant="outlined"
                                        value={adultMarkup}
                                        type="number"
                                        inputProps={{ min: 0}}
                                        disabled={markupPopUpStore || markupStatus}
                                        error={adultMarkupError !== ""}
                                        helperText={adultMarkupError !== "" && adultMarkupError}
                                        onChange={(e) => addMarkUpValueInfo(e)}
                                        onKeyDown={blockInvalidChar}
                                    />
                                    <span className="configProfit-dollar">{ window.selectedDollarValue}</span>
                                    {/* <span className="configProfit-plus"><i>x</i> {adultsCount} </span> */}
                                 {fareType === fareTypeConstant.NET && ccAllowed && airlineTransactionAmount > 0 && transitionFlag && markupLimitAmountPerAdult >= 0 &&
                                 <div>
                                    <input className='bd-markup-limit' disabled value={ window.selectedDollarValue  + markupLimitAmountPerAdult}/>
                                 </div>
                                 }   
                                {/* <div className={lang !== "fr" ? "bd-maxMarkup-Limit" : "bd-maxMarkup-Limit-fr"} style={{marginTop: adultMarkupError !== "" && "39px"}}>
                                  {t("Maximum Markup Allowed")} : ${adultMaxMarkupLimit}
                                </div>                                 */}
                                </div>                                
                            </div>                          
                        </div>
                    }


                    {childCount !== 0 &&
                        <div className="configProfit-col">
                            <div className="title">{t("Per child")} ( x {childCount} )</div>
                            <div className="configProfit-input pt-3">
                                {fareType === "Published" && contractdetails && contractdetails.amountPerChild && contractdetails.amountPerChild.amount > 0 &&
                                    // <div className="configProfit-input-label">
                                    //     Current Markup<span>{contractdetails?.amountPerChild?.amount}</span>
                                    // </div>}
                                    <div className="bd-pi-input">
                                    <TextField
                                    id="currentMarkup"
                                    label={t("Current Commission")}
                                    variant="outlined"
                                    value={contractdetails?.amountPerChild?.amount}
                                    disabled={true}
                                   
                                />
                                </div>
}
                                <div className={lang=="en"?"bd-pi-input":"bd-pi-input-fr"}>
                                   
                                    <TextField
                                        id="additionalMarkup2"
                                        label={t("Additional Markup")}
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ min: 0}}
                                        value={childMarkUpValue}
                                        disabled={markupPopUpStore || markupStatus}
                                        error={childMarkUpValueError !== ""}
                                        helperText={childMarkUpValueError !== "" && childMarkUpValueError}
                                        onChange={(e) => addChildMarkUpValueInfo(e)}
                                        onKeyDown={blockInvalidChar}
                                    />
                                    <span className="configProfit-dollar">{ window.selectedDollarValue}</span>
                                    {/* <span className="configProfit-plus"><i>x</i> {childCount} </span> */}
                                    {fareType === fareTypeConstant.NET && ccAllowed && airlineTransactionAmount > 0 && transitionFlag && markupLimitAmountPerChild >= 0 &&
                                 <div>
                                    <input className='bd-markup-limit' disabled value={ window.selectedDollarValue + markupLimitAmountPerChild}/>
                                 </div>
                                 }
                                {/* <div className={lang !== "fr" ? "bd-maxMarkup-Limit" : "bd-maxMarkup-Limit-fr"} style={{marginTop: childMarkUpValueError !== "" && "39px"}}>
                                {t("Maximum Markup Allowed")} : ${childMaxMarkupLimit}
                                </div>  */}
                                </div>
                            </div>
                        </div>
                    }

                    
                    {infantCount !== 0 &&
                        <div className="configProfit-col ">
                            <div className="title">{t("Per Infant")} ( x {infantCount} )</div>
                            <div className="configProfit-input pt-3">
                                {fareType === "Published" && contractdetails && contractdetails.amountPerInfantInLap && contractdetails.amountPerInfantInLap.amount > 0 &&
                                   
                                    <div className="bd-pi-input">
                                    <TextField
                                    id="currentMarkup"
                                    label={t("Current Commission")}
                                    variant="outlined"
                                    value={contractdetails?.amountPerInfantInLap?.amount}
                                    disabled={true}
                                   
                                />
                                </div>
}
             
                                <div className={lang=="en"?"bd-pi-input":"bd-pi-input-fr"}>
                                    <TextField
                                        id="additionalMarkup2"
                                        label={t("Additional Markup")}
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ min: 0}}
                                        value={infantMarkUpValue}
                                        disabled={markupPopUpStore || markupStatus}
                                        error={infantMarkUpValueError !== ""}
                                        helperText={infantMarkUpValueError !== "" && infantMarkUpValueError}
                                        onChange={(e) => addInfantMarkUpValueInfo(e)}
                                        onKeyDown={blockInvalidChar}
                                    />
                                    <span className="configProfit-dollar">{ window.selectedDollarValue}</span>
                                {fareType === fareTypeConstant.NET && ccAllowed && airlineTransactionAmount > 0 && transitionFlag && markupLimitAmountPerInfant >= 0 &&
                                 <div>
                                    <input className='bd-markup-limit' disabled value={ window.selectedDollarValue + markupLimitAmountPerInfant}/>
                                 </div>
                                 }
                                {/* <div className={lang !== "fr" ? "bd-maxMarkup-Limit" : "bd-maxMarkup-Limit-fr"} style={{marginTop: infantMarkUpValueError !== "" && "39px"}}>
                                 {t("Maximum Markup Allowed")} : ${infantMaxMarkupLimit}
                                </div>  */}
                                </div>
                            </div>
                        </div>
                    }
                                    {/* <div className="configProfit-col align-self-end">
                                    {markupStatus ? <div />
                                        : <><button className="configProfit-btn" onClick={handleApplyMarkUp}>{t("Apply Markup")}</button>
                                        <OverlayTrigger
                                            trigger={"click"}
                                            rootClose
                                            placement="top"
                                            delay={{ show: 100 }}
                                            overlay={
                                            <Popover className="seatView-popover">
                                                <div className="seatView-popover-row seatView-popover-amount">
                      
                                                <span className="airPort_name">{t("fare will be recalculated with your markup and price summary updated accordingly. If you agree with new fare, you need to click on Save Markup button in order to save the fare in your file.")}</span>
                                             </div>
                                            </Popover>}

                                            >
                                        <i style={{"paddingTop":"0.25rem"}}
                                        className="info-circle_img fas fa-info-circle">    
                                        </i>
                                        </OverlayTrigger></>
                                        }
                                    </div>

                    

                    <div className="configProfit-col align-self-end">
                        {markupStatus ? <div />
                           : <button className="SaveMarkup-btn" onClick={()=>{}}>{t("Save Markup")}</button>}


                    </div> */}
                    </div>
                    {(props.from==="booking" || props.from==="confirmation") &&
            <DefaultMarkup show={true} from={props.from} applyMarkup={handleApplyMarkUp} handleSaveMarup={handleSaveMarup} disableButton={disableButton}/>
            }
                   
                </div>
             
            </div>
            {/* EQUIVALENT FARE BREAKDOWN */}
            <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            keepMounted
            onClose={handleEquivalentFareClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="fareType-dialog"
            >{equivalentFareLoader ?
            <div className="bd-pi-contact-modal bd-alertBox fareType-table">
                <div className="head">
                <div className='fd-subHead'>
                <span>
                {t("Equivalent Published Fare")}
                </span>
                </div>
                    <button onClick={handleEquivalentFareClose}>&times;</button>
                </div>
               <div className="col-md-12 text-center mt-3">
                <SpinnerLoader />
                </div>
            </div>
             :           
            <div className="bd-pi-contact-modal bd-alertBox fareType-table">
                
                <>
                {equivalentFareValue === "NoFare" ?
                <>
                  <span>                 
                     {t("There is no equivalent published")}
                   </span>
                  <button style={{position:"absolute",top: "12px",right: "12px"}}onClick={handleEquivalentFareClose}>&times;</button>
                </>
                 :
                 <>
                 <div className="head">
                   <div className='fd-subHead'>
                    <span>
                        {t("Equivalent Published Fare")}
                    </span>
                    </div>
                  <button onClick={handleEquivalentFareClose}>&times;</button>
                </div>
                 <div className="bd-priceSummary-box">
                    <div className="bd-priceSummary">
                        <>
                           {amountInfo.map((data,index) => {
                            totalAmount += data.totalAmount
                            return(
                            <div key={index}>
                            <div className="bd-priceSummary-details sub-head">
                            <span>
                              {t("Per")} {data.type === PassengerType.INFANTINLAP ? t("Infant") : t(data.type)}
                            </span>
                            </div>
                            <div className="bd-priceSummary-details">
                                <span className="label-text">{t("Base Fare")} </span>                               
                                    <span className="label-net">
                                        { `${window.selectedDollarValue} `}{data.type === PassengerType.INFANTINLAP && (Number(data.baseAmount)).toFixed(2)}
                                        {data.type === PassengerType.ADULT && (Number(data.baseAmount)).toFixed(2)}
                                        {data.type === PassengerType.CHILD && (Number(data.baseAmount)).toFixed(2)}
                                    </span> 
                            </div>
                            {Number(data.totalTaxes) !== 0 &&
                            <div className="bd-priceSummary-details">
                                <span className="label-text"
                                >
                                    {t("Airline taxes & fees")}
                                </span>
                            <span className="label-net">{ `${window.selectedDollarValue} `} {(Number(data.totalTaxes)).toFixed(2)}</span>
                            </div>} 
                            {/* {bookingfee !== 0 &&
                                <div className="bd-priceSummary-details">
                                    <span className="label-text"
                                    >
                                        Booking fee
                                    </span>
                                    <span className="label-value"> </span>
                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(bookingfee)).toFixed(2)}</span>
                              </div>}
                            {ticketingfee !== 0 &&
                                <div className="bd-priceSummary-details">
                                    <span className="label-text"
                                    >
                                        Ticketing fee
                                    </span>
                                    <span className="label-value"> </span>
                                    <span className="label-net">{ `${window.selectedDollarValue} `}{(Number(ticketingfee)).toFixed(2)}</span>
                                </div>} */}
                          
                            <div className="bd-priceSummary-details ft-total">
                                <span className="label-text">
                                {t("Total")}                                   
                                </span> 
                                <span className="label-net">
                                { `${window.selectedDollarValue} `}
                                    {(Number(data.baseAmount) + Number(data.totalTaxes)).toFixed(2)}
                                </span>
                            </div>
                            </div>
                            )

                           })}
                        </>
                       
                    </div>

                </div>
                </>
                 }
                 </>               
               

            </div>
              }
            </Dialog>
             {/* {markupPopUp &&
                    <>  <div className="seat-udate-status ml-1 mr-1">
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>                          
                        <div className="saveQuote-modal">
                        <h4>{t("Do you want to apply these markups.")}</h4>
                        <div className="sqm-btns">
                        <button onClick={handleMarkupSave}>{t("Yes")}</button>
                        <button onClick={handleMarkupPopUpClose}>{t("No")}</button>
                        </div>
                        </div>
                        </div>
                    </>
            } */}
            
      
        </div>:<div/>}


            {/* END OF EQUIVALENT FARE BREAKDOWN */}      
        </>
    );
}

export default Markup;